body {
    padding: 0 !important;
    margin: 0;
    font-family: Poppins, Helvetica, sans-serif;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    text-align: left;
    background: url("../../assets/img/background-login.jpg") no-repeat center center !important;
}

html {
    font-family: sans-serif;
    -moz-text-size-adjust: 100%;
    text-size-adjust: 100%;
    -ms-text-size-adjust: 100%;
    -webkit-text-size-adjust: 100%;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

html,
body {
    height: 100%;
    margin: 0;
    padding: 0;
    font-size: 13px !important;
    font-weight: 300;
    font-family: Poppins, Helvetica, sans-serif;
    -ms-text-size-adjust: 100%;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

html a:hover,
html a:active,
html a:focus,
body a:hover,
body a:active,
body a:focus {
    text-decoration: none !important;
}

html a,
html button,
body a,
body button {
    outline: none !important;
}

body {
    display: flex;
    flex-direction: column;
    color: #646c9a;
}

.kt-grid__item--fluid {
    height: 80vh;
}
